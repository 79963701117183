import { configureStore } from "@reduxjs/toolkit"
import BreadcrumbSlice from "./breadcrumbReducer"
import drawerReducer from "./drawerReducer"
import storeReducer from "./storeReducer"
import usersReducer from "./usersReducer"
import rolesReducer from "./rolesReducer"
import modulesReducer from "./modulesReducer"
import categoryReducer from "./categoriesReducer"
import loggerReducer from "./loggerReducer"
import sizesReducer from "./sizesReducer"
import productsReducer from "./productsReducer"
import bannerReducer from "./bannerReducer"
import customer_slicer from "./customerReducer"
import order_slicer from "./orderReducer"
import deal_slicer from "./dealReducer"
import dashboard_slicer from "./dashboardReducer"
import courierReducer from "./courierReducer"
import deliveryReducer from "./deliveryReducer"
import careersReducer from "./careersReducer"
import smsManagementReducer from "./smsManagmentReducer"
import promocodeReducer from "./promocodeReducer"
import pointsReducer from "./pointsReducer"

// ==============================|| REDUX - MAIN STORE ||============================== //

export const store = configureStore({
  reducer: {
    drawer: drawerReducer,
    store: storeReducer,
    users: usersReducer,
    roles: rolesReducer,
    modules: modulesReducer,
    breadcrumb: BreadcrumbSlice,
    category: categoryReducer,
    logger: loggerReducer,
    sizes: sizesReducer,
    product: productsReducer,
    banner: bannerReducer,
    customer: customer_slicer,
    order: order_slicer,
    deal: deal_slicer,
    dashboard: dashboard_slicer,
    courier: courierReducer,
    delivery: deliveryReducer,
    careers: careersReducer,
    smsManagement: smsManagementReducer,
    promoCode: promocodeReducer,
    points: pointsReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
