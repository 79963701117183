import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { capitalize } from "lodash"
import moment from "moment"
import apiServices from "services/RequestHandler"
import { dateFormat, timeFormat, utc_time_zone } from "store/constants"

export interface PointsState {
  all_points: any
  single_point: any
  create_point: any
  activity: boolean
}

interface APIParams {
  end_point: string
  body: any
}

const initialState: PointsState = {
  all_points: [],
  single_point: {},
  create_point: {},
  activity: false,
}

export const get_all_points = createAsyncThunk(
  "/all-points",
  async (data: any) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        data.permission_name
      )
      return {
        ...response,
      }
    } catch (err) {
      console.log(err)
    }
  }
)

export const get_single_points = createAsyncThunk(
  `/point/:id`,
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "point")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const add_new_point = createAsyncThunk(
  "/point/add",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "point/add"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const points_slicer = createSlice({
  name: "points",
  initialState,
  reducers: {
    handle_clear_form: (state: PointsState, action: any) => {
      state.all_points = []
      state.single_point = {}
      state.create_point = {}

      state.activity = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_all_points.pending, (state, { payload }) => {
        state.activity = true
      })

      .addCase(get_all_points.fulfilled, (state, { payload }) => {
        let points = [] as any
        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data?.[index]
          points.push([
            { name: item?.id, hidden: true },
            { name: item?.title?.en || "-" },
            { name: item?.title?.ge || "-" },
            {
              value: item?.is_active,
              name: item?.is_active ? "Active" : "Inactive",
              is_bool: true,
              color: item?.is_active ? "#6EC531" : "#d2112b",
            },
            { name: capitalize(item?.loyalty_points_type) || "-" },
            { name: item?.loyalty_points || "0" },
            {
              name:
              item?.start_date ?
                moment(item?.start_date)
                  .utcOffset(utc_time_zone)
                  .format(`${dateFormat}`) : "-",
            },
            {
              name:
              item?.end_date ?
                moment(item?.end_date)
                  .utcOffset(utc_time_zone)
                  .format(`${dateFormat}`) : "-",
            },
            {
              name: item?.created_at
                ? moment(item?.created_at)
                    .utcOffset(utc_time_zone)
                    .format(`${dateFormat} ${timeFormat}`)
                : "-",
            },
            item?.added_by || "-",
            {
              name: item?.updated_at
                ? moment(item?.updated_at)
                    .utcOffset(utc_time_zone)
                    .format(`${dateFormat} ${timeFormat}`)
                : "-",
            },
            item?.modified_by || "-",
          ])
        }
        state.all_points = points
        state.activity = false
      })

      .addCase(add_new_point.pending, (state, { payload }) => {
        state.activity = true
      })

      .addCase(add_new_point.fulfilled, (state, { payload }) => {
        try {
          state.create_point = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      .addCase(add_new_point.rejected, (state, { payload }) => {
        try {
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      .addCase(get_single_points.pending, (state, { payload }) => {
        state.activity = true
      })

      .addCase(get_single_points.fulfilled, (state, { payload }) => {
        try {
          state.single_point = payload?.data as any
          state.activity = false
        } catch (error) {
          console.log(error)
        }
      })
  },
})

export const { handle_clear_form } = points_slicer.actions

export default points_slicer.reducer
